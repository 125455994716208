import Menu from '~/icons/menu.svg';
import React from 'react';
import { useMobileMenu } from '../../N35MobileMegaMenu/hooks/useMobileMenu';
import { StyledSmallIconButton, StyledHeaderSmallIconOptionText } from '../styled';
import { useTranslation } from '~/shared/utils/translation';

export const MobileMegaMenuButton = () => {
    const { toggle } = useMobileMenu();
    const { translate } = useTranslation();

    return (
        <StyledSmallIconButton
            href="/"
            onClick={(e) => {
                toggle();
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <Menu />{' '}
            <StyledHeaderSmallIconOptionText>
                {translate('header.menu')}
            </StyledHeaderSmallIconOptionText>
        </StyledSmallIconButton>
    );
};
