import styled from '@emotion/styled';
import { Link, Text } from '~/shared/components';

export const StyledPromotedContent = styled.div(({ theme: { space } }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: space[5],
}));

export const StyledPromotedItem = styled.div({
    position: 'relative',
});

export const StyledCaption = styled(Text)(({ theme: { space } }) => ({
    padding: `${space[2]} 0`,
}));

export const StyledCoverLink = styled(Link)({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
});
