import { Image, Text } from '~/shared/components';
import { StyledButton, StyledResetButton } from '~/shared/components/Button/styled';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '~/theme/breakpoints';

const StyledAnchor = StyledResetButton.withComponent('a');

export const MetaMenuLink = styled(StyledAnchor)(({ theme }) => ({
    position: 'relative',
    fontSize: theme.fontSizes.sm,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
    background: theme.colors.white,
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    ':not(:last-child)': {
        borderBottom: `1px solid ${theme.colors.grey40}`,
    },
    svg: {
        width: 16,
        height: 16,
        '& path': {
            stroke: theme.colors.black,
        },
    },
}));

export const MetaMenuLinkContent = styled.div<{ truncate?: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        padding: theme.space[3],
        fontSize: theme.fontSizes.xs,
    }),
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })),
);
export const MetaMenuLinkContentText = styled(Text)(({ theme }) => ({
    [breakpoints.sm]: {
        fontSize: theme.fontSizes.xs,
    },
    [breakpoints.xs]: {
        fontSize: theme.fontSizes.xs,
    },
}));

export const MetaMenuLinkBottomContent = styled(StyledButton)<{ truncate?: boolean }>(
    ({ theme }) => ({
        margin: theme.space[3],
    }),
    ifProp('truncate', () => ({
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    })),
);

export const StyledSuffix = styled.div(({ theme }) => ({
    textAlign: 'right',
    color: 'lightgrey',
    paddingLeft: 0,
    paddingRight: theme.space[3],
    lineHeight: '25px',
    height: 25,

    svg: {
        height: 16,
        width: 16,
    },
}));

export const StyledImage = styled(Image)({});
export const Gradient = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0,0,0,0) 58%, rgba(0,0,0,0.5))',
});

export const MetaMenuLinkBottom = styled(Text)(({ theme }) => ({
    color: theme.colors.white,
    textTransform: 'uppercase',
    fontSize: theme.fontSizes.xs,
}));
