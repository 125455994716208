import React from 'react';
import { NavigationNode } from '~/lib/data-contract';
import ArrowRight from '~/icons/arrow-right.svg';
import {
    StyledItem,
    StyledContent,
    StyledSuffix,
    StyledImage,
    StyledContentContainer,
    Gradient,
    NodeText,
    StyledFlexItem,
    StyledListItem,
} from './styled';
import { Link } from '~/shared/components';
import { Image } from '~/shared/components';
import { useMobileMenuState } from '../../context/MobileMenuContext';
import { useMobileMenuState as useMenuState } from '../../hooks/useMobileMenuState';

type NodeItemProps = {
    node: NavigationNode;
    onSelectNode?: (node: NavigationNode) => void;
};

export const NodeItem = ({ node, onSelectNode }: NodeItemProps) => {
    const { isFirstNode } = useMobileMenuState();
    const { isOpen, setIsOpen } = useMenuState();
    const { children, link } = node;
    const asButton = !!children?.length;
    const onClickHandler = () => {
        if (onSelectNode) {
            onSelectNode(node);
        }
    };

    const handleClose = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    let additionalProps: any = {
        as: Link,
        href: link?.url,
        target: link?.target,
        onClick: handleClose,
    };

    if (asButton) {
        additionalProps = {
            as: 'button',
            onClick: onClickHandler,
        };
    }

    return (
        <>
            {isFirstNode ? (
                <StyledItem {...additionalProps}>
                    {(node.image || node.mobileImage) && (
                        <>
                            <StyledImage
                                src={node.mobileImage?.src || node.image?.src || ''}
                                width={195}
                                height={165}
                                layout="responsive"
                                objectFit="cover"
                                alt={node.mobileImage?.alt || node.image?.alt}
                                sizes="50vw"
                            ></StyledImage>
                            <Gradient></Gradient>
                        </>
                    )}
                    <StyledContentContainer>
                        <StyledContent>
                            <NodeText variant="display3">{link.title}</NodeText>
                        </StyledContent>
                    </StyledContentContainer>
                    {!(node.image || node.mobileImage) && (
                        <StyledSuffix children={asButton ? <ArrowRight /> : null} />
                    )}
                </StyledItem>
            ) : (
                <StyledListItem {...additionalProps}>
                    <StyledFlexItem>{link.title}</StyledFlexItem>
                    <StyledFlexItem auto>
                        {(node.image || node.mobileImage) && (
                            <Image
                                src={node.mobileImage?.src || node.image?.src || ''}
                                alt={node.mobileImage?.alt || node.image?.alt}
                                width={40}
                                height={40}
                            />
                        )}
                    </StyledFlexItem>
                </StyledListItem>
            )}
        </>
    );
};
