import styled from '@emotion/styled';
import { breakpoints } from '~/theme/breakpoints';
import { StyledResetButton } from '~/shared/components/Button/styled';
import { StyledText } from '~/shared/components';

export const StyledIconButtonTop = styled(StyledResetButton)<{
    textColor: string;
    backgroundColor?: string;
    offset: number | null;
}>(({ theme, textColor, backgroundColor, offset }) => ({
    padding: `${theme.space[1]} ${theme.space[2]} ${theme.space[1]} ${theme.space[2]}`,
    color: textColor || theme.colors.black,
    backgroundColor: backgroundColor || theme.colors.white,
    height: 48,
    width: 154,
    borderRadius: theme.space[4],
    gap: theme.space[2],
    zIndex: 99,
    boxShadow: theme.shadows.button,
    svg: {
        width: 24,
        height: 24,
        transform: 'rotate(90deg)',
        '& path': {
            fill: textColor,
            strokeWidth: 4,
        },
    },
    display: 'flex',
    position: 'fixed',
    bottom: offset ?? theme.space[6],
    marginBottom: offset ? theme.space[3] : undefined,
    right: theme.space[3],
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const StyledTopButtonOptionText = styled(StyledText)<{ textColor?: string }>(
    ({ theme, textColor }) => ({
        color: textColor || theme.colors.black,
        textTransform: 'uppercase',
        [breakpoints.xs]: {
            display: 'flex',
            fontSize: theme.fontSizes['sm'],
            fontWeight: theme.fontWeights.medium,
            lineHeight: theme.lineHeights.base,
            letterSpacing: 0,
        },
    }),
);
