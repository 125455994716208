import styled from '@emotion/styled';
import { Link, Text } from '~/shared/components';
import { StyledResetButton } from '~/shared/components/Button/styled';

export const StyledMenuPage = styled.div(({ theme }) => ({
    height: '100%',
    padding: `${theme.space[2]}`,
    backgroundColor: theme.colors.white,
}));

export const StyledBackButton = styled(StyledResetButton)(({ theme }) => ({
    fontSize: theme.fontSizes.xs,
    lineHeight: '25px',
    textAlign: 'left',
    maxWidth: '90%',
    gridArea: 'back-button',

    span: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        lineHeight: 1.2,
        paddingLeft: 5,
    },

    svg: {
        height: 25,
        width: 25,
        flex: '0 0 25px',
    },
}));

export const StyledHeaderLink = styled(Link)(({ theme }) => ({
    color: theme.colors.black,
    textAlign: 'right',
    textDecoration: 'underline',
    lineHeight: theme.fontSizes.xl,
    marginTop: 3,
    gridArea: 'header-link',
}));

export const StyledHeader = styled.div(({ theme }) => ({
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    justifyContent: 'space-between',
    marginBottom: theme.space[2],
    gap: theme.space[3],
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    gridTemplateRows: '25px 1fr',
    gridTemplateAreas: `
        "back-button back-button"
        "headline header-link"
    `,
}));

export const StyledMenuHeadline = styled(Text)({
    gridArea: 'headline',
});
