import React from 'react';
import { MetaNavigationNode } from '~/lib/data-contract';
import { Link } from '~/shared/components';
import {
    MetaMenuLinkContent,
    MetaMenuLink,
    StyledSuffix,
    MetaMenuLinkBottomContent,
    MetaMenuLinkBottom,
    MetaMenuLinkContentText,
} from './styled';

import Chevron from '~/icons/chevron.svg';
import Flex from '~/shared/components/Flex';
import { useMobileMenuState } from '../../hooks/useMobileMenuState';

type MenuPageProps = {
    metaMenu: MetaNavigationNode;
};

export const MetaMenu = ({ metaMenu }: MenuPageProps) => {
    const { isOpen, setIsOpen } = useMobileMenuState();

    const handleClose = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    return (
        <>
            <Flex grow column>
                {metaMenu.links?.map((link) => {
                    const additionalProps: any = {
                        as: Link,
                        href: link?.url,
                        onClick: handleClose,
                    };
                    return (
                        <MetaMenuLink key={link.id} {...additionalProps}>
                            <MetaMenuLinkContent>
                                <MetaMenuLinkContentText>{link.text}</MetaMenuLinkContentText>
                            </MetaMenuLinkContent>
                            <StyledSuffix children={<Chevron />} />
                        </MetaMenuLink>
                    );
                })}
            </Flex>
            {!!metaMenu.bottomLink?.url && (
                <MetaMenuLink
                    as={Link}
                    title={metaMenu.bottomLink.title}
                    href={metaMenu.bottomLink.url}
                    target={metaMenu.bottomLink.target}
                >
                    <MetaMenuLinkBottomContent variant="primary">
                        <MetaMenuLinkBottom variant="display3">
                            {metaMenu.bottomLink.text}
                        </MetaMenuLinkBottom>
                    </MetaMenuLinkBottomContent>
                </MetaMenuLink>
            )}
        </>
    );
};
