import styled from '@emotion/styled';
import { Text, Link } from '~/shared/components';
import { StyledResetButton } from '~/shared/components/Button/styled';
import Flex from '~/shared/components/Flex';
const StyledAnchor = StyledResetButton.withComponent(Link);

export const StyledMenuWrapper = styled.div({
    flex: 1,
});

export const StyledMenu = styled.div(({ theme: { space } }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: space[5],
    justifyContent: 'center',
}));

export const StyledItem = styled(StyledAnchor)(({ theme }) => ({
    position: 'relative',
    fontSize: theme.fontSizes.sm,
    textAlign: 'left',
    marginTop: 0,
    marginBottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
}));

export const Gradient = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background:
        'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 60%, rgba(0,0,0,1) 100%)',
});
export const CenterText = styled.div({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
});
export const NodeText = styled(Text)(({ theme }) => ({
    color: theme.colors.white,
    marginBottom: theme.space[4],
    textTransform: 'uppercase',
    textAlign: 'center',
}));

export const SubMenu = styled(Flex)(({ theme }) => ({
    marginTop: theme.space[2],
    [`${SubMenuItem}`]: {
        paddingRight: theme.space[2],
    },
}));

export const SubMenuItem = styled(Flex)({
    flexBasis: 0,
    flexGrow: 1,
});
