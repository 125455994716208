import React, { FC } from 'react';
import Search from '~/icons/search.svg';
import ArrowLeft from '~/icons/arrow-left.svg';
import CloseIcon from '~/icons/close.svg';
import {
    SearchPlaceholder,
    SearchPlaceholderIconContainer,
    SearchPlaceholderTextContainer,
} from '../N20MainMenu/styled';

type IconProps = {
    name: 'search' | 'arrowLeft' | 'close';
};

type SearchPlaceholderProps = {
    icon: 'search' | 'arrowLeft' | 'close';
    children?: React.ReactNode;
    onClick?: () => void;
    className?: string;
    isVisible?: boolean;
};

const Icon: FC<IconProps> = ({ name }) => {
    const icons = {
        search: Search,
        arrowLeft: ArrowLeft,
        close: CloseIcon,
    };

    const SelectedIcon = icons[name];
    return <SelectedIcon />;
};

export const SearchPlaceholderComponent: FC<SearchPlaceholderProps> = ({
    icon,
    children,
    onClick,
    className,
    isVisible = true,
}) => {
    if (!isVisible) {
        return null;
    }
    return (
        <SearchPlaceholder
            isShowSearch={icon === 'arrowLeft'}
            tabIndex={0}
            onClick={onClick}
            onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    onClick?.();
                }
            }}
            className={className}
        >
            <SearchPlaceholderIconContainer>
                <Icon name={icon} />
            </SearchPlaceholderIconContainer>
            <SearchPlaceholderTextContainer>{children}</SearchPlaceholderTextContainer>
        </SearchPlaceholder>
    );
};
